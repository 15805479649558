/* ================================================================== */
/* This file has a mobile-to-tablet, and tablet-to-desktop breakpoint */
/* ================================================================== */

$media-mobile-max: 46rem !default;
$media-tablet-min: 46.0625rem !default;
$media-tablet-max: 62rem !default;
$media-desktop-min: 62.0625rem !default;

@import "unsemantic-vars";
@import "unsemantic-ie-snap";

@include unsemantic-grid-placeholders;
@include unsemantic-grid-base-tablet;

@media (max-width: $media-mobile-max) {
  @include unsemantic-grid-placeholders(mobile);
  @include unsemantic-grid-scoped(mobile);
}

@media (min-width: $media-tablet-min) and (max-width: $media-tablet-max) {
  @include unsemantic-grid-placeholders(tablet);
  @include unsemantic-grid-scoped(tablet);
}

@media (min-width: $media-desktop-min) {
  @include unsemantic-grid-placeholders(desktop);
  @include unsemantic-grid-scoped(desktop);
}
